<template>
  <div class="cooperativeGroup">
      <navigationBar name="协作组"></navigationBar>
      <div class="content">
          <div 
            class="contentItem" 
            v-for="(item, index) in cooperativeGroup" 
            :style="`background-image: url(${item.imageUrl})`"
            @click="goTo(`/CooperativeGroupList/${item.name}`)"
            :key="index">
              <p>{{item.name}}</p>
              <p>协作组</p>
          </div>
      </div>
  </div>
</template>

<script>
import navigationBar from "@/components/navigationBar"
export default {
  name: "CollageList",
  data() {
      return {
          cooperativeGroup: [
              {
                  name: '华北区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/huabei.png')
              },
              {
                  name: '华中区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/huazhong.png')
              },
              {
                  name: '华东（一）区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/huadong1.png')
              },
              {
                  name: '华东（二）区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/huadong2.png')
              },
              {
                  name: '东北区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/huabei.png')
              },
              {
                  name: '西北区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/xibei.png')
              },
              {
                  name: '华南区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/huanan.png')
              },
              {
                  name: '西南区域',
                  imageUrl: require('@/assets/cooperativeGroupImg/xinan.png')
              },
          ]
      }
  },
  components: {
      navigationBar: navigationBar
  },
  mounted() {
      
  }
};
</script>

<style scoped>
    .content {
        margin: 0;
        padding: 40px 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .content .contentItem {
        height: 320px;
        width: 320px;
        letter-spacing: 2px;
        margin-bottom: 10pt;
        border-radius: 15px;
        background-color: black;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 33px;
        color: white;
        background-size: cover;
    }
</style>
